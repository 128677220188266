.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.grid {
  display: grid;
  font-family: 'Montserrat', sans-serif;
  gap: 2rem;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 0.36fr));
  font-size: small;
}

.bannerBackground {
  object-fit: cover;
  background-color: var(--saver-navbar);
  width: 100%;
  height: 44vh;
  border-radius: 10px;
}

.header {
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 2rem;
}

.merchantLogo {
  /* width: 11rem; */
  max-width: 247px;
  max-height: 276px;
  /* height: 10rem; */
  border: 2px solid var(--saver-lightBackground);
  padding: 1.1rem;
  background-color: white;
  margin-top: -3.8rem;
  float: left;
  box-shadow: 0px 3px 10px rgba(173, 179, 191, 0.3);
  border-radius: 10px;
}

.topBar {
  padding: 1rem;
  display: flex;
  flex-direction: column;
}

.title {
  padding: 0 1rem 0.5rem;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--saver-textcolor);
}

.businessStatus {
  padding-left: 1rem;
}

.schedule {
  color: var(--saver-textColor);
  font-weight: 500;
  padding: 0.5rem 1rem 0.5rem;
  background-color: transparent;
  border: none;
}

.tabsContainer {
  padding-top: 3rem;
}

.tabsHeader {
  background-color: transparent;
  border-bottom: 2px solid var(--saver-separator);
  width: 100%;
}

.tabTitle {
  background-color: transparent;
  border: none;
  padding-bottom: 0.5rem;
  color: var(--saver-textColor);
  font-size: medium;
  margin: 0rem 3rem 0rem;
  font-family: 'Montserrat', sans-serif;
  cursor: pointer;
}

.disabledTab {
  background-color: transparent;
  border: none;
  padding-bottom: 0.5rem;
  color: var(--saver-underline);
  font-size: medium;
  margin: 0rem 3rem 0rem;
  font-family: 'Montserrat', sans-serif;
  cursor: pointer;
}

.active {
  font-weight: 600;
  border-bottom: 3px solid var(--saver-red);
}

.tabTitle:hover {
  font-weight: 600;
}

.tabContent {
  background-color: #fafafa;
  padding: 1.1rem;
}

.mainInfo {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.2rem;
}

.sectionTitle {
  font-weight: 600;
  color: var(--saver-textColor);
  margin-bottom: 0;
}

.sectionDetail {
  font-size: small;
  padding: 0.5rem;
  margin-left: 0.5rem;
}

.hoursListed {
  font-size: small;
  color: var(--saver-titles);
  padding: 0.5rem;
  margin: 0;
  text-align: start !important;
  font-weight: 400;
}

.sectionDetail a {
  cursor: pointer;
  width: fit-content;
}

.dayDetail {
  display: flex;
  padding: 0.25rem;
  flex-direction: row;
}

.dayListed {
  font-size: small;
  color: var(--saver-titles);
  margin-right: 0.75rem;
  width: 50%;
  text-align: left;
  font-weight: 400;
}

.isClosed {
  font-style: italic;
  font-size: small;
  color: var(--saver-red);
  margin: 0;
  padding: 0.5rem;
  text-align: start !important;
}

.scheduleHolder {
  width: 50%;
  text-align: left;
  margin-left: 0.9rem;
  margin-top: 0.25rem;
}

.dailyHours {
  width: 50%;
}

.icon {
  margin-left: 0.8rem;
  margin-top: 0.25rem;
  float: right;
  clear: right;
  cursor: pointer;
}

.icon:hover {
  transform: scale(1.1);
}

.noData {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-column: span 3;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1.5rem;
  padding-top: 5rem;
  color: var(--saver-textColor);
  font-size: 1.1rem;
  background-color: white;
}

.addDetails {
  padding: 1.1rem;
}

.locationsContainer {
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 1.5rem;
}

.locationDetail {
  display: flex;
  width: 100%;
  align-items: flex-start;
  align-content: flex-start;
  gap: 10px;
  flex-direction: row;
  margin-bottom: 1rem;
}

.locationDetail span:first-child {
  width: 15rem;
}

.locationItem {
  /* margin-left: 1.2rem; */
  width: 10rem;
}

.mapContainer {
  width: 100%;
  height: 40vh;
}

.detailLink {
  border: none;
  background-color: transparent;
  color: var(--saver-textColor);
  font-size: medium;
  margin: 0.6rem 0;
  margin-left: 0.5rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  cursor: pointer;
}

.detailLink:hover {
  transform: scale(1.01);
}

.iconLeft {
  float: left;
  margin-right: 1rem;
  width: 18px;
}

.modal {
  margin: auto;
  justify-content: center;
  align-items: center;
  padding: 3rem;
  outline: 0;
}

.modalContent {
  padding: 0.5rem;
  margin: 0 auto;
  overflow: auto;
  width: 80%;
  max-width: 900px;
  max-height: 650px;
  background-color: var(--saver-navbar);
  align-self: center;
  display: flex;
  justify-content: flex-end;
}

.socialSection {
  margin-top: 1.25rem;
}

.socialMediaWrapper {
  display: flex;
  flex-direction: row;
  padding: 0.5rem;
  padding-top: 0;
}

.apartmentMapContainer {
  padding: 0.25rem;
  height: 80vh;
  max-height: 95vh;
  position: relative;
}

.listViewContainer {
  background-color: white;
  padding: 0.25rem;
  max-height: 95vh;
  overflow-y: auto;
  display: grid;
  font-family: 'Montserrat', sans-serif;
  gap: 2rem;
  grid-template-columns: repeat(auto-fill, minmax(23rem, 0.5fr));
  font-size: small;
}

.popOverButton {
  border: none;
  background: none;
  width: max-content;
}

.popOver {
  margin: 0rem;
  text-align: left;
  z-index: 2;
}

.popOver h4 {
  margin: 0.36rem;
  font-size: 14px;
}

.popOver p {
  margin: 0.36rem;
  font-size: 11px;
  margin-bottom: 0;
}

.popOverLink {
  background-color: transparent;
  outline: 0;
  border: none;
  margin-bottom: 0.36rem;
  margin-top: 0.5rem;
  font-size: 14px;
  color: #4580eb;
  z-index: 999;
  cursor: pointer;
}

.apartmentCardOnMap {
  background-color: white;
  z-index: 2;
  position: absolute;
  width: 400px;
  height: 276px;
  right: 0;
  margin-right: 0.4rem;
  margin-top: 0.16rem;
}

.tools {
  background-color: white;
  z-index: 3;
  position: fixed;
  padding: 0.25rem;
  border-radius: 6px 0 0 6px;
  box-shadow: 0px 3px 10px rgba(165, 166, 168, 0.3);
}

.toolItem {
  font-family: 'Montserrat', sans-serif;
  padding: 0.24rem;
  margin: 0.16rem 0.25rem;
  min-width: 24px;
  min-height: 16px;
  border-radius: 6px;
  outline: 0;
  background-color: var(--saver-lightBackground);
  color: var(--saver-titles);
  border: 1px solid var(--saver-underline);
  cursor: pointer;
}

.toolItem:hover {
  color: var(--saver-textColor);
}

.toolItemClose {
  font-family: 'Montserrat', sans-serif;
  padding: 0.24rem;
  margin: 0.16rem 0.25rem;
  min-width: 24px;
  min-height: 16px;
  border-radius: 6px;
  outline: 0;
  background-color: var(--saver-red);
  color: white;
  border: 1px solid var(--saver-red);
  cursor: pointer;
}

.toolItemClose:hover {
  color: var(--saver-lightBackground);
}

@media screen and (max-width: 1024px) {
  .scheduleHolder {
    width: 60%;
  }

  .bannerBackground {
    height: 32vh;
  }

  .title {
    padding: 0 0rem 0.5rem;
    font-size: large;
    font-weight: bold;
    color: var(--saver-textcolor);
  }
}

.couponGrid {
  display: grid;
  font-family: 'Montserrat', sans-serif;
  gap: 2.5rem;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 0.5fr));
}

@media screen and (max-width: 820px) {
  .bannerBackground {
    height: 24vh;
  }

  .merchantLogo {
    max-width: 123.5px;
    max-height: 138px;
    margin-top: -2rem;
  }

  .businessStatus {
    font-size: small;
  }

  .schedule {
    font-size: smaller;
  }

  .header {
    margin-left: 1rem;
  }

  .tabsContainer {
    padding-top: 1.75rem;
  }

  .tabTitle {
    font-size: small;
    margin: 0rem 1.5rem 0rem;
  }

  .topBar {
    padding: 0.5rem 0.3rem;
  }

  .title {
    padding: 0;
    font-size: large;
  }

  .scheduleHolder {
    width: 80%;
    margin-left: 0.9rem;
  }

  .modal {
    padding: 1rem;
    padding-top: 2rem;
  }

  .modalContent {
    padding: 0.25rem;
    width: 100%;
    height: fit-content;
  }

  .apartmentCardOnMap {
    width: 290px;
  }
}

@media screen and (max-width: 600px) {
  .bannerBackground {
    height: 24vh;
  }

  .title {
    padding: 0.5rem 1rem 0rem;
  }

  /* .merchantLogo {
    width: 7rem;
    height: 6rem;
    padding: 0.5rem;
    margin-top: -1.75rem;
  } */

  .businessStatus {
    font-size: small;
    margin-top: -0.25rem;
  }

  .schedule {
    font-size: 10px;
  }

  .header {
    margin-left: 0.36rem;
  }

  .topBar {
    padding: 0.16rem;
  }

  .tabTitle {
    font-size: smaller;
    margin: 0rem 0.3rem 0rem;
  }

  .scheduleHolder {
    width: 100%;
  }

  .dayListed {
    width: 40%;
  }

  .dailyHours {
    width: 60%;
  }

  .couponGrid {
    gap: 1.5rem;
    justify-content: center;
    grid-template-columns: repeat(auto-fill, minmax(16rem, 0.5fr));
  }

  .noData {
    font-size: 0.75rem;
  }

  .apartmentCardOnMap {
    margin-right: 0;
    margin-top: 0;
  }


  .locationsContainer span {
    font-size: small;
  }

  .listViewContainer {
    padding: 0;
    gap: 1rem;
    grid-template-columns: repeat(auto-fill, minmax(23rem, 0.5fr));
  }

  .tabContent {
    padding: 0.5rem;
  }
}

@media screen and (min-width: 2100px) {
  .bannerBackground {
    height: 36vh;
  }
}

@media screen and (max-width: 428px) {
  .couponGrid {
    grid-template-columns: none;
  }
}

@media screen and (max-width: 400px) {
  .tabTitle {
    font-size: small;
    margin: 0;
  }

  .active {
    margin: 0;
  }
}

@media only screen and (max-device-width: 480px) {
  .locationItem {
    width: 8rem;
  }
}

@media screen and (max-width: 375px) {
  .title {
    font-size: 16px;
  }
}

@media screen and (width: 1668px) and (height: 2388px) {
  .tabContent {
    height: fit-content;
  }

  .apartmentMapContainer {
    height: 60vh;
  }
}
