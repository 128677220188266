.container {
  padding: 2rem 0rem 2rem;
  display: flex;
  width: 88%;
  margin: 0 auto;
  max-width: 1440px;
  flex-direction: row;
  justify-content: flex-start;
  margin: 0 auto;
}

.filtersContainer {
  display: flex;
  flex-direction: column;
  width: 25%;
  padding: 0.16rem;
  z-index: 1;
}

.filterHeader {
  background-color: var(--saver-red);
  color: white;
  padding: 1rem;
  font-weight: 700;
  font-size: large;
}

.resultsContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 75%;
  padding: 0.75rem;
  padding-top: 0;
  margin-left: 1rem;
}

.breadcrumb {
  width: 100%;
  color: var(--saver-textColor);
  padding: 1rem;
  padding-top: 0;
  padding-left: 0;
  left: 0;
  font-size: small;
}

.breadcrumbList {
  font-family: 'Montserrat', sans-serif;
  display: flex;
  flex: 1;
  flex-direction: row;
  list-style: none;
  color: var(--saver-inactive);
  text-transform: capitalize;
  padding-left: 0;
  font-size: 14px;
  cursor: pointer;
}

.breadcrumbList li {
  margin-right: 1.1rem;
  cursor: pointer;
}

.breadcrumbList li::after {
  content: url('../assets/arrow.png');
  position: absolute;
  margin-left: 0.3rem;
}

.breadcrumbList li:last-child::after {
  content: none;
}

.tabsContainer {
  width: 100%;
  padding: 0;
  margin-top: -1rem;
}

.card {
  width: 100%;
  max-width: 400px;
  height: 276px;
  max-height: 276px;
  background-color: #c4c4c4;
  border-radius: 6px;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  border: 0.5px solid var(--saver-separator);
}

.bottomBanner {
  width: 100%;
  height: 70px;
  background: rgba(0, 0, 0, 0.7);
  box-shadow: 0px 3px 10px rgba(173, 179, 191, 0.3);
  border-radius: 6px;
  padding: 0.5rem 1rem;
  color: white;
  position: absolute;
  bottom: 0;
}

.bottomBanner img {
  margin-right: 0.5rem;
}

.bottomBanner p {
  margin-top: 0;
}

.favoriteApartment {
  float: right;
  padding: 0.16rem;
  margin-top: 0.25rem;
  margin-right: 0.25rem;
  cursor: pointer;
  width: 36px;
}

.singleAptTitle {
  font-weight: 600;
  font-size: large;
  color: #666666;
}

.singleAptDetail {
  font-weight: 400;
  font-size: large;
  color: #666666;
}

.singleAptDescription {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: medium;
  font-style: normal;
  color: var(--saver-textColor);
  margin-bottom: 1rem;
}

.singleAptContainer {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 2px solid var(--saver-separator);
  display: flex;
  flex-direction: column;
}

.singleAptTopContainer {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 2px solid var(--saver-separator);
  display: flex;
  flex-direction: column;
}

.singleAptContainer span {
  margin-top: 0.5rem;
}

.singleAptBorderlessContainer {
  margin-bottom: 3rem;
  padding-bottom: 1rem;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.finalDetailContainer {
  margin-bottom: 3rem;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.singleAptManagerContainer {
  margin-bottom: 3rem;
  padding-bottom: 1rem;
  background-color: var(--saver-lightBackground);
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.singleAptDarkTxt {
  font-weight: 600;
  color: var(--saver-textColor);
  text-align: center;
}

.singleAptDetail2 {
  font-weight: 500;
  color: var(--saver-textColor);
  text-align: center;
  margin-top: -0.5rem;
}

.managerLogo {
  max-width: 245px;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
}

.filtersWrapper {
  padding: 0 o.5rem;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background: #ffffff;
  box-shadow: 0px 2px 6px rgba(173, 179, 191, 0.3);
  z-index: 2;
  padding: 0.75rem 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.filtersWrapper p {
  font-weight: 600;
  font-size: 16px;
  color: var(--saver-titles);
}

.carouselContainer {
  max-height: 500px;
  margin-bottom: 1.25rem;
  padding: 0.25rem;
  margin-bottom: 2rem;
}

.singlePhoto {
  width: 664px;
  height: 491px;
  border-radius: 8px;
  align-self: center;
  margin: 0 auto;
}

.carouselPhoto {
  width: 664px;
  height: 491px;
  border-radius: 8px;
  align-self: center;
  margin: 0.5rem 1rem;
}

.carouselContainer .singlePhoto {
  display: flex;
  justify-content: center;
  align-items: center;
}

.thumbContainer {
  height: 491px;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  overflow-y: auto;
  background-color: #fbfbfb;
  padding: 0.25rem;
}

/* .thumbContainer::-webkit-scrollbar {
  width: 0 !important;
} */

.thumbButton {
  outline: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  border: none;
  cursor: pointer;
}

.imgThumb {
  height: 216px;
  width: 280px;
  max-width: 313px;
  border-radius: 6px;
  margin: 0.25rem 0;
}

.selectedImg {
  width: 664px;
  padding: 0.5rem;
  max-width: 664px;
  height: 491px;
}

.currentSelected {
  border-radius: 8px;
  max-width: 664px;
  height: 491px;
}

.resetAll {
  outline: 0;
  border: none;
  text-decoration: underline;
  color: white;
  background-color: transparent;
  font-weight: 500;
  float: right;
  font-size: 14px;
  margin-top: 0.16rem;
  cursor: pointer;
}

.defaultUnselected {
  width: 40px;
  max-width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 32px;
  border: 1px solid var(--saver-red);
  color: var(--saver-red);
  padding: 0.5rem;
  border-radius: 10px;
  background-color: transparent;
}

.selectedButton {
  width: 40px;
  max-width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 32px;
  border: 1px solid var(--saver-red);
  color: white;
  padding: 0.5rem;
  border-radius: 10px;
  background-color: var(--saver-red);
}

.defaultUnselectedLarge {
  width: 56px;
  max-width: 56px;
  max-height: 32px;
  border: 1px solid var(--saver-red);
  color: var(--saver-red);
  padding: 0.5rem;
  border-radius: 10px;
  background-color: transparent;
  text-align: center;
}

.selectedButtonLarge {
  width: 56px;
  max-width: 56px;
  max-height: 32px;
  border: 1px solid var(--saver-red);
  color: white;
  padding: 0.5rem;
  border-radius: 10px;
  background-color: var(--saver-red);
  text-align: center;
}

.buttonGroup {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1.1rem 0;
}

.buttonGroup button {
  cursor: pointer;
}

.buttonGroup button:hover {
  transform: scale(1.025);
}

.moreFilters {
  font-family: 'Montserrat', sans-serif;
  cursor: pointer;
  background-color: transparent;
  outline: 0;
  border: none;
  font-weight: 600;
  color: var(--saver-red);
  font-size: 16px;
}

.lessFilters {
  font-family: 'Montserrat', sans-serif;
  cursor: pointer;
  background-color: transparent;
  outline: 0;
  border: none;
  font-weight: 600;
  color: var(--saver-red);
  font-size: 16px;
  margin: 0 auto;
}

.searchBtn {
  font-family: 'Montserrat', sans-serif;
  cursor: pointer;
  align-self: center;
  background-color: var(--saver-red);
  color: white;
  border: none;
  outline: 0;
  margin-top: 1.5rem;
  padding: 0.25rem;
  border-radius: 26px;
  width: 126px;
  height: 30px;
}

.amenitiesColumn {
  column-count: 2;
}

.amenities {
  display: flex;
  padding: 0px;
  margin: 0;
  align-items: center;
}

.amenitiesImages {
  margin-right: 10px;
  width: 20px;
  height: 20px;
}

.petPolicy {
  display: flex;
  align-items: center;
}

.petPolicy h5 {
  margin-right: 5px;
}

.mapContainer {
  width: 400px;
  height: 250px;
  margin-right: 6%;
}

.linktoMerchant {
  font-weight: 500;
  color: var(--saver-textColor);
  text-decoration: underline;
  cursor: pointer;
}

.linktoSite {
  font-weight: 600;
  color: var(--saver-textColor);
  text-decoration: none;
  cursor: pointer;
  margin-right: 0.5rem;
}

.contactManagerBtn {
  font-family: 'Montserrat', sans-serif;
  max-width: 170px;
  height: 46px;
  background-color: var(--saver-red);
  border-radius: 26px;
  border: 1px solid var(--saver-red);
  color: white;
  font-weight: 700;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  margin-top: 1.25rem;
  margin-bottom: 1rem;
}

.contactManagerBtn:hover {
  transform: scale(1.025);
}

.contactManagerBtn a:hover {
  color: white;
}

.shareBtn {
  font-family: 'Montserrat', sans-serif;
  color: var(--saver-red);
  font-weight: 600;
  font-size: 15px;
  outline: 0;
  cursor: pointer;
  background-color: transparent;
  border: none;
}

.shareBtn:hover {
  transform: scale(1.025);
}

.shareBtn img {
  margin: 0 0.5rem;
}

.filterOptions {
  width: 80%;
  margin: 0 auto;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
  color: var(--saver-red);
}

.filterOptions span {
  font-size: small;
}

.selectLeasing {
  width: 100%;
  margin-top: 0.75rem;
  font-family: 'Montserrat', sans-serif;
}

.areasContainer {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.areaBtn {
  width: 100%;
  border: 1px solid var(--saver-red);
  color: var(--saver-red);
  padding: 0.5rem;
  border-radius: 10px;
  background-color: transparent;
  text-align: center;
  min-height: 50px;
  margin: 0.25rem;
  cursor: pointer;
}

.areaBtn:hover {
  transform: scale(1.025);
}

.selectedAreaBtn {
  width: 100%;
  border: 1px solid var(--saver-red);
  color: white;
  padding: 0.5rem;
  border-radius: 10px;
  background-color: var(--saver-red);
  text-align: center;
  margin: 0.25rem;
  cursor: pointer;
}

.señectedAreaBtn:hover {
  transform: scale(1.025);
}

.amenityInFilter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  text-align: left;
  padding: 0 0.25rem;
}

.amenityInFilter p {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  color: var(--saver-textColor);
}

.amenityInFilter span {
  color: white !important;
}

.openFilters {
  float: right;
  border-radius: 6px;
  outline: 0;
  padding: 0.25rem;
  margin-top: 0.25rem;
  font-family: 'Montserrat', sans-serif;
  border: 1px solid var(--saver-textColor);
  display: none;
}

.floorPlans {
  font-family: 'Montserrat', sans-serif;
  color: var(--saver-textColor);
  border-collapse: collapse;
}

.floor {
  border-top: 2px solid var(--saver-separator);
}

.floorPlans td {
  padding: 1rem;
}

.openFilters:hover {
  transform: scale(1.025);
}

.headerRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.floorPlansModal {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 1rem;
  max-width: 80%;
  max-height: 80%;
  background-color: white;
}

.floorPlansModal img {
  align-self: center;
  margin: 0 auto;
}

.floorPlansModal p {
text-align: center;
font-family: 'Montserrat', sans-serif;
}
.newCarousel {
  display: 'flex';
  flex-direction: 'row';
  overflow-x: auto;
}

.newCarousel img {
  margin: 1rem;
}

.contactButtons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 50%;
}

.callManagerBtn {
  display: none;
  font-family: 'Montserrat', sans-serif;
  max-width: 170px;
  width: 40%;
  height: 46px;
  background-color: white;
  border-radius: 26px;
  border: 1px solid var(--saver-red);
  color: var(--saver-red);
  font-weight: 700;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 1.25rem;
  margin-bottom: 1rem;
}

.aptSection {
  margin-bottom: 5px;
}

@media screen and (max-width: 1024px) {
  .container {
    width: 94%;
  }

  .filtersContainer {
    display: none;
  }

  .resultsContainer {
    width: 100%;
  }

  .card {
    max-width: 400px;
    height: 276px;
  }

  .openFilters {
    display: block;
  }

  .selectedImg {
    display: none;
  }

  .thumbContainer {
    flex-direction: row;
    height: fit-content;
    background-color: transparent;
  }

  .thumbButton {
    margin: 1rem;
    cursor: none;
  }

  .imgThumb {
    width: 313px;
    cursor: none;
  }

  .singlePhoto {
    width: 332px;
    height: 256px;
    border-radius: 4px;
  }
}

@media screen and (max-width: 800px) {
  .mapContainer {
    margin-right: 0;
  }
}

@media screen and (max-width: 820px) {
  .carouselPhoto {
    width: 382px;
    height: 300px;
    border-radius: 8px;
    align-self: center;
    margin: 0.5rem 1rem;
  }
}

@media screen and (max-width: 600px) {
  .singlePhoto {
    width: 166px;
    height: 128px;
    border-radius: 2px;
  }

  .finalDetailContainer {
    flex-direction: column;
  }

  .mapContainer {
    width: 100%;
    height: 250px;
  }

  .petPolicy {
    width: 100%;
  }

  .contactButtons {
    width: 100%;
  }

  .bottomBanner p {
    font-size: small;
  }

  .bottomBanner {
    height: 76px;
  }
}

@media screen and (max-width: 360px) {
  .finalDetailContainer {
    flex-direction: column;
  }

  .mapContainer {
    width: 100%;
    height: 250px;
  }

  .petPolicy {
    width: 100%;
  }
}

@media screen and (max-width: 428px) {
  .carouselPhoto {
    width: 240px;
    height: 200px;
    margin: 0.5rem;
  }
}

@media screen and (width: 1668px) and (height: 2388px) {
  .mapContainer {
    height: 600px;
  }
}
.cardFav {
  position: absolute;
  margin-top: -0.2rem;
  margin-right: -0.2rem;
  right: 0;
  z-index: 2;
  padding: 1rem;
}