.navlinks {
  margin-right: 1%;
  margin-top: 0;
  height: 100%;
  margin: 0 auto;
  max-width: 1440px;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-weight: 100;
  list-style: none;
}

.mobileLinks {
  display: none;
}
.navlinks li {
  margin-left: 1%;
  margin-right: 1%;
}

.navlinks a:hover,
.navlinks a:active,
.navlinks a.active {
  color: #e2e0e0;
  font-weight: bolder;
}

.navlinks li:hover {
  transform: scale(1.1);
}

.logoutBtn {
  font-family: 'Montserrat', sans-serif;
  color: var(--saver-red);
  outline: 0;
  border: 0;
  background-color: transparent;
  font-weight: 600;
  font-size: medium;
  display: none;
}

@media screen and (max-width: 600px) {
  .navlinks {
    color: black;
    height: 50vh;
    flex-direction: column;
    margin: 0;
    padding: 0;
    justify-content: center;
    align-items: center;
  }

  .logoutBtn {
    display: block;
  }

  .mobileLinks {
    display: flex;
  }
  .navlinks li {
    margin: 0.5rem;
    padding: 0.5rem;
  }

  .navlinks a:hover,
  .navlinks a:active,
  .navlinks a.active {
    background: #464646;
    border: 2px solid transparent;
    border-color: #dacccc;
    color: white;
  }
}
