.topNavBar {
  background: #464646;
  height: 3rem;
}

.mobileIcons {
  display: none;
}

.navWrapper {
  width: 100%;
  z-index: 2;
  background-color: var(--saver-navbar);
  display: flex;
  align-items: center;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
}

.navContent {
  display: flex;
  margin: 0 auto;
  max-width: 1440px;
  justify-content: space-between;
  width: 80%;
  padding: 4px;
  margin: 0 auto;
}

.logoContainer {
  padding: 6px;
  flex: 0.5;
}

.logoContainer img {
  width: 90%;
  max-width: 385px;
  max-height: 166px;
  cursor: pointer;
  margin-left: -1rem;
}

.logoContainer:hover {
  transform: scale(1.0001);
}

.optionsContainer {
  display: flex;
  flex-direction: column;
  flex: 0.5;
  justify-content: space-evenly;
  align-items: flex-end;
}

.buttonGroup {
  display: flex;
  /* justify-content: space-between; */
  justify-content: flex-end;
  align-items: center;
  width: 50%;
  margin-right: 0.16rem;
}

.heart {
  border: none;
  cursor: pointer;
  background: none;
}

.heart:hover {
  transform: scale(1.05);
}

.profile:hover {
  transform: scale(1.05);
}

.iconGroup {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 50%;
}

.searchBar {
  width: 70%;
  outline: none;
}

.searchBar input {
  height: 35px;
  width: 100%;
  border-radius: 20px;
  border: 1px solid #c9c9c9;
  padding: 5px;
  color: #888888;
  background: url(../assets/search.svg) no-repeat scroll 7px 7px #ffffff;
  background-position: left center;
  background-position-x: 40px;
  text-indent: 60px;
  filter: drop-shadow(0px 3px 10px rgba(218, 218, 218, 0.6));
}

.searchBar input:focus {
  outline: none;
}

.searchBar .searchResultsList {
  position: absolute;
  background: #ffffff;
  z-index: 1001;
  overflow: visible;
  width: 500px;
  box-shadow: 0px 2px 6px rgba(173, 179, 191, 0.3);
}

.searchResultsList ul {
  list-style-type: none;
}

.searchResultsList h3 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.41px;
  color: #e11b22;
}

.searchResultsList hr {
  height: 2px;
  background: #dbdbe7;
  width: 80%;
  margin-left: 0;
}

.searchResultsList ul li {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.41px;
  color: #5c6269;
  margin-bottom: 12px;
}

.searchResultsList ul li text {
  cursor: pointer;
}

.link {
  width: fit-content;
}

.modalContent {
  background-color: white;
  padding: 1rem;
}

.offerTitle {
  font-weight: 600;
}

.searchResultsList li:hover {
  background-color: var(--saver-navbar);
}

.loginModal {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 4% auto;
  width: 23rem;
  height: 11rem;
  box-shadow: 0px 3px 10px rgba(173, 179, 191, 0.3);
  outline: 0;
  border-radius: 8px;
}

.loginModal h1 {
  font-size: 20px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 8px;
  padding-left: 1rem;
}

.modalP {
  font-size: 1rem;
  font-weight: 500;
  margin: 0 auto;
  margin-bottom: 10px;
  margin-top: -4px;
}

.loginModal div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modalLink {
  font-size: 0.84rem;
  color: var(--saver-textColor);
  margin: 3px;
}

.closeModal {
  font-size: 0.84rem;
  margin: 3px;
  color: var(--saver-textColor);
  cursor: pointer;
}

.closeModal:hover {
  color: black;
  font-weight: bolder;
  transform: scale(1.025);
}

.modalLink:hover {
  color: black;
  font-weight: bolder;
  transform: scale(1.025);
}

.favButton {
  background: none;
  box-sizing: border-box;
  border: none;
  cursor: pointer;
}

@media (max-width: 2560px) {
  .searchBar .searchResultsList {
    width: 593px;
  }
}

@media (max-width: 1440px) {
  .searchBar .searchResultsList {
    width: 490px;
  }
}

@media (max-width: 1024px) {
  .searchBar .searchResultsList {
    width: 320px;
  }

  .link {
    font-size: 12px;
  }

  .searchResultsList h3 {
    font-size: 14px;
  }
}

@media (max-width: 820px) {
  .optionsContainer {
    flex: 0.9;
  }

  .logoContainer {
    flex: 0.4;
  }

  .searchBar .searchResultsList {
    width: 330px;
  }

  .link {
    font-size: 10px;
  }
}

@media (max-width: 768px) {
  .logoContainer img {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .topNavBar {
    display: none;
  }

  .logoContainer {
    flex: 1;
    margin-left: -1rem;
  }

  .logoContainer img {
    width: 168px;
    height: 75px;
  }

  .mobileIcons {
    display: flex;
    justify-content: space-between;
    text-align: center;
    width: 40%;
  }

  .mobileIcons img {
    width: 50%;
    height: 100%;
  }

  .mobileIcons button {
    width: 60%;
    height: 100%;
    border: none;
    padding: 0;
  }

  .profile {
    width: 6vw !important;
  }

  .optionsContainer {
    display: none;
  }

  .navContent {
    width: 88%;
  }

  .searchBar .searchResultsList {
    width: 330px;
  }

  .searchBar {
    width: 100%;
  }

  .searchBar input {
    border-radius: 12px;

    background-position-x: 20px;
    text-indent: 40px;
  }
}
