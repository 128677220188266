body {
  padding: 0;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@tailwind utilities;

:root {
  --saver-red: #e11b22;
  --saver-gray: #5c6269;
  --saver-open: #26b466;
  --saver-closed: #5e0407;
  --saver-background: #f8f8f8;
  --saver-navbar: #f2f2f2;
  --saver-iconColor: #464646;
  --saver-titles: #5c6269;
  --saver-textColor: #404852;
  --saver-inactive: #959595;
  --saver-card1: #3d3d3d;
  --saver-card2: #bc0001;
  --saver-separator: #dbdbe7;
  --saver-darkBtn: #6b6c6d;
  --saver-lightBackground: #fbfbfb;
  --saver-underline: #d4d4e4;
}

.redeemModal {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1.75rem auto;
  width: fit-content;
  height: fit-content;
  box-shadow: 0px 3px 10px rgba(173, 179, 191, 0.3);
  outline: 0;
}

.filtersContainerSmall,
.resetModal {
  position: absolute;
  padding: 1.1rem 0;
  overflow: scroll;
  height: 100%;
  max-width: 330px;
  margin: 0 auto;
  display: block;
}

.resetModal {
  max-width: 500px;
}

.filtersContainerSmallContent {
  padding: 0.5rem;
  background-color: white;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
}

.modalContent {
  padding: 2rem;
  background-color: white;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
}

.closeButton {
  outline: 0;
  border: none;
  align-self: flex-end;
  background-color: white;
  margin-bottom: 1rem;
  float: right;
  cursor: pointer;
}

.closeAptButton {
  outline: 0;
  border: none;
  align-self: flex-start;
  background-color: transparent;
  float: right;
  padding: 0.5rem;
  cursor: pointer;
  position: relative;
}

.imageHolder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: -3rem;
}

.buttonHolder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-bottom: -1rem;
  margin-top: -1.25rem;
}

.closeButton:hover {
  transform: scale(1.02);
}

.modalContent span {
  font-weight: 600;
  color: var(--saver-textColor);
  font-family: 'Montserrat', sans-serif;
}

.modalContent p {
  font-weight: 700;
  color: var(--saver-textColor);
  font-family: 'Montserrat', sans-serif;
  font-size: large;
  text-align: center;
  margin-top: -3.25rem;
}

.underConstruction {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3.5rem;
}

.underConstruction span {
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  margin-top: 1.1rem;
}

.lightBackground {
  width: 100%;
  margin-top: -20%;
  margin-bottom: -16%;
  z-index: -1;
  position: relative;
  bottom: 0;
}

.mainButton {
  font-family: 'Montserrat', sans-serif;
  border-radius: 26px;
  background-color: var(--saver-red);
  color: var(--saver-background);
  border: none;
  padding: 6px;
  width: 80%;
  font-weight: 600;
  min-width: 120px;
  cursor: pointer;
  font-size: small;
}

.mainButton:disabled {
  background-color: var(--saver-inactive);
  color: var(--saver-background);
  cursor: not-allowed;
}

.darkbutton {
  font-family: 'Montserrat', sans-serif;
  border-radius: 26px;
  background-color: var(--saver-inactive);
  color: var(--saver-background);
  border: none;
  padding: 6px;
  width: 80%;
  font-weight: 600;
  cursor: pointer;
  font-size: small;
}

.mainButton:hover {
  transform: scale(1.05);
  color: var(--saver-lightBackground);
}

.mainButton a:hover {
  color: var(--saver-lightBackground);
}

.secondaryButton {
  font-family: 'Montserrat', sans-serif;
  border-radius: 26px;
  background-color: var(--saver-background);
  color: var(--saver-red);
  border: 1px solid var(--saver-red);
  padding: 6px;
  width: 80%;
  font-weight: 600;
  min-width: 120px;
  cursor: pointer;
  font-size: small;
}

.secondaryButton:hover {
  transform: scale(1.05);
  color: var(--saver-red);
}

.secondaryButton a:hover {
  transform: scale(1.05);
  color: var(--saver-red);
}

.inactiveIcon {
  border: none;
  padding: 2px;
  background-color: transparent;
  color: var(--saver-inactive);
}

.inactiveIcon:hover {
  transform: scale(1.1);
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  font-weight: 600;
  color: var(--saver-textColor);
}

.slide-in-left-enter {
  transform: translateX(-100%);
}

.slide-in-left-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: all 200ms;
}

.slide-in-left-exit {
  transform: translateX(0%);
  opacity: 1;
}

.slide-in-left-exit-active {
  transform: translateX(-100%);
  opacity: 0;
  transition: all 200ms;
}

* {
  box-sizing: border-box;
}

.open {
  color: var(--saver-open);
  font-weight: 500;
}

.closed {
  color: var(--saver-red);
  font-weight: 500;
}

.iconLeft {
  float: left;
  margin-right: 0.5rem;
  width: 16px;
}

.marker {
  color: var(--saver-lightBackground);
  font-family: 'Montserrat', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.saverMarkerContainer {
  cursor: pointer;
}

.saverMarkerTitle {
  color: black;
  font-size: smaller;
  font-weight: bold;
  margin-top: 0.16rem;
}

.saverMarkerDetail {
  color: var(--saver-gray);
  font-size: small;
  display: none;
}

.swal2-styled.swal2-confirm {
  background-color: #e11b22 !important;
}

.swal2-styled.swal2-confirm:focus {
  box-shadow: 0 0 0 3px rgb(225 27 34 / 50%) !important;
}

@media screen and (max-width: 1024px) {
  .mainButton .secondaryButton {
    font-size: small;
  }
}

@media screen and (min-width: 2560px) {
  .lightBackground {
    width: 100%;
    margin-top: -10%;
    margin-bottom: -20%;
    z-index: -1;
    position: relative;
    bottom: 0;
  }
}

.Demo__some-network {
  vertical-align: top;
  display: inline-block;
  margin-right: 10px;
  text-align: center;
}

.Demo__some-network__share-count {
  display: inline-flex;
  justify-content: center;
  white-space: nowrap;
  overflow: visible;
  width: 0;
  margin-top: 3px;
  font-size: 12px;
}

.Demo__some-network__share-button {
  cursor: pointer;
}

.Demo__some-network__share-button:hover:not(:active) {
  opacity: 0.75;
}

.Demo__some-network__custom-icon {
  width: 32px;
  height: 32px;
}

.swal2-small {
  background-color: transparent !important;
  pointer-events: none !important;
}

.swal2-small>.swal2-popup {
  box-shadow: #0000003d 0px 2px 8px;
  display: flex !important;
  align-items: center;

  position: absolute;
  bottom: 1rem;
  right: 1rem;
  height: auto !important;
  width: auto !important;

  padding: 0px !important
}

.swal2-small .swal2-icon {
  display: none !important;
}

.swal2-small .swal2-actions {
  display: none !important;
}

.swal2-small .swal2-html-container {
  margin: 0.75rem 1rem !important;
  font-size: 1rem;
}

.swal2-small .swal2-icon-success {
  border: 4px solid #26b466 !important;
}

.swal2-small .swal2-icon-error {
  border: 4px solid #e11b22 !important;
}

.swal2-small .swal2-icon-info {
  border: 4px solid #5c6269 !important;
}

.swal2-small .swal2-icon-question {
  border: 4px solid #5c6269 !important;
}

.swal2-small .swal2-icon-warning {
  border: 4px solid #f8bb86 !important;
}