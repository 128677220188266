.container {
  padding-top: 3.5rem;
  display: flex;
  width: 80%;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 auto;
  padding-bottom: 2rem;
}

.sentCodeText {
  font-size: 0.65rem;
  color: var(--saver-gray);
}

.headerWrapper {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;
  text-align: center;
}

.privacyLink {
  text-decoration: underline;
}

.responsiveLogo {
  width: 72%;
  margin: 0 auto;
}

.slogan {
  color: var(--saver-gray);
  margin-top: 1rem;
}

.disclaimerWrapper {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;
  text-align: center;
  bottom: 0;
}

.disclaimer {
  color: var(--saver-gray);
  margin-top: 1rem;
  font-size: 10px;
}

.primaryTitle {
  font-weight: 700;
  margin-top: 2rem;
  margin-bottom: 0.16rem;
  color: var(--saver-red);
}

/* SIGN IN */
.signInform {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto;
}

.signInform p {
  font-size: small;
  color: var(--saver-gray);
  font-weight: 600;
}

.signinLink {
  display: flex;
  flex-direction: column;
}

.formData {
  justify-content: center;
  align-items: center;
  padding: 1rem;
  display: flex;
  width: 100%;
  flex-direction: column;
  box-shadow: 0px 3px 10px rgba(173, 179, 191, 0.3);
  border-radius: 6px;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.formInput,
.phoneDesc {
  width: 100%;
  font-family: 'Montserrat', sans-serif;
  border: none;
  margin: 1rem 0;
  margin-bottom: 6px;
  padding: 0.25rem;
  border-bottom: 1px solid var(--saver-underline);
}

.formInputError {
  border-bottom: 1px solid #e31e20;
}

.formInput:focus,
.phoneDesc:focus,
.formInputError:focus {
  border: none;
  outline: none;
  border-bottom: 1px solid var(--saver-gray);
}

.formInputError:focus {
  border-bottom: 1px solid #e31e20;
}

.phoneDesc {
  margin-bottom: 16px;
}

.resetPswd {
  font-family: 'Montserrat', sans-serif;
  border: none;
  background-color: transparent;
  color: var(--saver-gray);
  margin: 0;
  font-weight: 500;
  font-size: 12px;
  align-self: flex-end;
  margin-top: -0.36rem;
}

.backLink {
  font-family: 'Montserrat', sans-serif;
  border: none;
  background-color: transparent;
  color: var(--saver-red);
  font-weight: 500;
  font-size: 12px;
  align-self: flex-start;
  z-index: 2;
  margin-bottom: 0.5rem;
  cursor: pointer;
}

.newAccount {
  font-family: 'Montserrat', sans-serif;
  border: none;
  background-color: transparent;
  color: var(--saver-gray);
  margin: 0.75rem 0;
  font-weight: 600;
}

.resetPswd:hover {
  transform: scale(1.02);
  color: var(--saver-textColor);
}

.backLink:hover {
  transform: scale(1.02);
}

.newAccount:hover {
  transform: scale(1.02);
  color: var(--saver-textColor);
}

.divider {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: var(--saver-gray);
  font-size: small;
  margin: 1rem 0;
}

.dividerLine {
  margin: 0 0.3rem;
  color: var(--saver-gray);
  font-weight: 500;
}

.socialMediaContainer {
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: center;
}

.mediaIcon {
  background: #ffffff;
  box-shadow: 0px 4px 6px rgba(141, 141, 141, 0.3);
  border-radius: 6px;
  border: none;
  padding: 0.36rem;
  margin: 0 1rem;
}

.accountIcon {
  margin: 0.25rem 0.5rem;
}

.mediaIcon:hover {
  box-shadow: 0px 5px 6px rgba(104, 104, 104, 0.3);
  transform: scale(1.02);
}

/* SIGN UP */
.signUpform {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto;
  border-radius: 6px;
  margin-top: 1.5rem;
  margin-bottom: 1.25rem;
  padding: 1rem;
  box-shadow: 2px 4px 6px rgba(114, 113, 113, 0.3);
}

.signUpContainer {
  width: fit-content;
  width: -webkit-fill-available;
}

.signUpLink {
  text-decoration: underline;
  font-style: italic;
  cursor: pointer;
  color: var(--saver-red);
}

.signUpform p {
  font-size: small;
  color: var(--saver-gray);
  font-weight: 600;
}

.row {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: -1.25rem;
  flex-flow: row;
}

.signUpform .row {
  margin: auto;
  gap: 1rem;
  align-items: flex-start;
}

.col {
  display: flex;
  flex-direction: column;
  padding: 1.1rem;
  width: inherit;
  padding: 8px 6%;
}

.phoneInput {
  margin-bottom: 0;
  width: -webkit-fill-available;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;
}

.errorMessage {
  font-size: x-small;
  color: #e31e20;
  font-weight: 600;
  margin-bottom: 0.5rem;
  text-align: left;
}

@media (min-width: 230px) {
  .row {
    flex-flow: wrap;
  }

  .phoneInput {
    margin-bottom: 1rem;
  }

  .signUpform {
    width: 100%;
    gap: 0.5rem;
  }
}

@media (min-width: 320px) {
  .row {
    flex-flow: wrap;
  }

  .phoneInput {
    margin-bottom: 1rem;
  }

  .signUpform {
    width: 95%;
    gap: 0.5rem;
  }
}

@media (min-width: 576px) {
  .row {
    flex-flow: wrap;
  }

  .col {
    padding: 8px 6%;
  }

  .phoneInput {
    margin-bottom: 1rem;
  }

  .signUpform {
    width: 95%;
  }
}

@media (min-width: 768px) {
  .row {
    flex-flow: row;
  }

  .col {
    padding: 14px 2%;
  }

  .phoneInput {
    margin-bottom: 0;
  }
}

@media (min-width: 992px) {
  .phoneInput {
    margin-bottom: 0;
  }

  .col {
    padding: 14px 2%;
  }

  .signUpform {
    width: 45%;
  }
}

@media (min-width: 1200px) {
  .phoneInput {
    margin-bottom: 0;
  }
}

@media (min-width: 1400px) {
  .phoneInput {
    margin-bottom: 0;
  }
}

.signUpNote {
  text-align: left;
}

.resendBlock {
  margin-bottom: 1rem;
}

.resendBlock button {
  background-color: transparent;
  border: none;
  font-size: 16px;
}