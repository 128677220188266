.magazineWrapper {
  min-height: fit-content;
  padding: 2rem;
  display: flex;
  width: 100%;
  max-width: 1000px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
}

.page {
  background-color: var(--saver-background);
}

.disclaimer {
  display: none;
  font-family: 'Montserrat', sans-serif;
  font-size: 10px;
  padding: 1rem 0;
  margin-bottom: -1.5rem;
  text-align: center;
}

.toolbar {
  background-color: var(--saver-background);
  width: 100%;
  margin: 1rem 1.5rem;
  padding: 0.25rem 0.75rem;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.toolbar p {
  font-size: small;
  font-family: 'Montserrat', sans-serif;
  color: var(--saver-textColor);
}

@media screen and (max-width: 820px) {
  .magazineWrapper {
    min-height: fit-content;
    padding: 2rem 1.5rem;
  }

  .toolbar {
    margin: 0.75rem;
    padding: 0.5rem;
  }
}

@media screen and (max-width: 620px) {
  .disclaimer {
    display: block;
    margin-top: -1.5rem;
  }

  .toolbar {
    display: none;
  }
}
