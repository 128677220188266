.footerLink {
  color: var(--saver-navbar);
  font-weight: 600;
  font-size: 0.9rem;
  line-height: 1.5rem;
  letter-spacing: -0.361765px;
  opacity: 0.75;
}

.footerLink:hover {
  font-weight: 600;
  color: var(--saver-background);
}

/* 125% */
@media screen and (max-width: 1200px) {
  .footerLink {
    font-size: 0.7rem;
  }
}

@media screen and (max-width: 1024px) and (max-height: 1366px) {
  .footerLink {
    font-size: 0.65rem;
  }
}

@media screen and (max-width: 850px) {
  .footerLink {
    font-size: 0.57rem;
  }
}

@media screen and (max-width: 768px) and (max-height: 1024px) {
  .footerLink {
    font-size: 0.35rem;
  }
}

@media screen and (max-width: 768px) and (max-height: 709px) {
  .footerLink {
    font-size: 0.48rem;
  }
}

@media screen and (max-width: 750px) {
  .footerLink {
    font-size: 0.43rem;
  }
}

@media screen and (max-width: 600px) {
  .footerLink {
    font-size: 0.9rem;
  }
}
