.mobileDrawer {
  display: none;
}

@media screen and (max-width: 600px) {
  .logo {
    width: 100%;
    padding: 16px;
    text-align: center;
  }

  .logo button {
    border: none;
    outline: none;
  }

  .logo img {
    width: 90%;
    height: 100%;
  }
  .mobileDrawer {
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;
    height: 100vh;
    width: 60%;
    background: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  }
}
