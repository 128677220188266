.content {
  margin-top: 10%;
}

.content h1 {
  color: #404852;
  margin: 0;
  font-size: 30px;
  margin-left: 30%;
  margin-bottom: 0.75rem;
}

.banner {
  position: relative;
  background: linear-gradient(rgba(0, 0, 0, 0.327), rgba(0, 0, 0, 0.3)),
    url('../assets/delivery.png') center/cover;
  /* background: linear-gradient(rgba(0, 0, 0, 0.527), rgba(0, 0, 0, 0.5)),
    url('../assets/delivery.png') center/cover; */
  height: 30vh;
  max-height: 196px;
}

.phone {
  width: 22%;
  position: absolute;
  left: 8%;
  bottom: 0;
}

.bannerContent {
  color: #ffffff;
  margin: 1rem;
  position: absolute;
  top: 15%;
  left: 34%;
}

.privacyLink {
  text-decoration: underline;
  cursor: pointer;
}

.privacyLink:hover {
  transform: scale(1.01);
  color: white;
}

.stores img {
  width: 100%;
  max-width: 290px;
  height: 100%;
}

.rightStore {
  margin-left: 6rem;
}

.footerWrapper {
  width: 100%;
  z-index: 2;
  background-color: var(--saver-iconColor);
  padding: 6px;
  display: flex;
  flex-direction: column;
}

.footerContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
  margin: 0 auto;
  border-bottom: 1px solid var(--saver-inactive);
}

.copyrightHolder {
  width: 80%;
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  font-size: 0.7rem;
  color: var(--saver-inactive);
}

.footerContent p {
  color: var(--saver-navbar);
  margin-left: 0.5rem;
  margin-right: 0.3rem;
}

.footerContent img:hover {
  transform: scale(1.05);
}

.logoContainer {
  padding: 6px;
  display: flex;
  align-items: center;
}

.optionsContainer {
  display: flex;
  align-items: center;
}

.optionsContainer a {
  margin: 0 0.5rem;
}

.footerElements {
  display: flex;
  flex-wrap: wrap;
}

.icons {
  display: flex;
  align-items: center;
}

.icons p {
  font-weight: 700;
}

.socialIcons {
  width: 20%;
  height: 100%;
}

@media screen and (max-width: 1440px) {
  .banner {
    height: 28vh;
    max-height: 220px;
  }
}

@media screen and (min-width: 2560px) {
  .container {
    max-height: fit-content;
  }

  .content h1 {
    font-size: 2.5rem;
    margin-bottom: 0.75rem;
  }

  .banner {
    height: 28vh;
    max-height: 300px;
  }

  .stores img {
    max-width: 360px;
  }

  .phone {
    max-height: 445px;
  }
}

@media screen and (max-width: 1366px) and (max-height: 1024px) {
  .banner {
    height: 21vh;
  }
}

@media screen and (max-width: 1310px) {
  .banner {
    height: 27vh;
  }
}

/* 125% */
@media screen and (max-width: 1200px) {
  .content h1 {
    font-size: 1.4rem;
  }

  .banner {
    height: 28vh;
  }

  .stores img {
    width: 33%;
  }
}

/* ipad pro */
@media screen and (max-width: 1024px) and (max-height: 1366px) {
  .content h1 {
    font-size: 1.2rem;
  }

  .banner {
    height: 12vh;
  }

  .icons p {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 1024px) and (max-height: 768px) {
  .banner {
    height: 22vh;
    max-height: 139px;
  }
}

@media screen and (max-width: 1024px) and (max-height: 734px) {
  .content h1 {
    font-size: 1.2rem;
  }
  .banner {
    height: 23vh;
  }

  .footerContent {
    width: 90%;
    padding-bottom: 0.5rem;
  }

  .logoContainer {
    flex: 0.4;
  }

  .copyrightHolder {
    width: 90%;
    font-size: 0.56rem;
  }
}

/* 150% */
@media screen and (max-width: 960px) {
  .content h1 {
    font-size: 1.2rem;
  }

  .banner {
    height: 28vh;
  }

  .bannerContent {
    left: 32%;
  }

  .stores img {
    width: 33%;
  }

  .rightStore {
    margin-left: 4rem;
  }

  .phone {
    width: 20%;
  }
}

/* 175% */
@media screen and (max-width: 850px) {
  .content h1 {
    font-size: 1.1rem;
    margin-left: 14%;
    text-align: center;
  }
  .banner {
    height: 30vh;
  }

  .bannerContent {
    left: 26.5%;
  }

  .stores img {
    width: 35%;
  }

  .phone {
    left: 3%;
  }

  .icons p {
    font-size: 0.8rem;
  }

  .socialIcons {
    width: 15%;
    height: 100%;
  }
}

@media screen and (max-width: 768px) and (max-height: 1024px) {
  .content h1 {
    font-size: 1.1rem;
  }
  .banner {
    height: 10vh;
    max-height: 139px;
  }

  .stores img {
    width: 154px;
  }

  .icons p {
    font-size: 0.6rem;
  }

  .socialIcons {
    width: 10%;
    height: 100%;
  }
}

@media screen and (max-width: 768px) and (max-height: 709px) {
  .banner {
    height: 16vh;
  }

  .socialIcons {
    width: 10%;
    height: 100%;
  }
}

/* 200% */
@media screen and (max-width: 750px) {
  .content h1 {
    font-size: 1rem;
  }

  .banner {
    height: 25vh;
    max-height: 139px;
  }

  .icons p {
    font-size: 0.5rem;
  }

  .socialIcons {
    width: 14%;
    height: 100%;
  }
}

@media screen and (max-width: 600px) {
  .content {
    margin-top: 20%;
  }

  .content h1 {
    margin: 0.5rem 1rem;
    font-weight: 800;
    font-size: 1.5rem;
  }

  .banner {
    max-height: 139px;
  }

  .phone {
    display: none;
  }

  .bannerContent {
    top: 0;
    left: 0;
    display: block;
    margin: 0px;
    text-align: center;
  }

  .stores {
    margin-top: 2rem;
    display: flex;
  }

  .stores img {
    width: 90%;
  }

  .rightStore {
    margin: 0;
  }

  .footerWrapper {
    text-align: center;
  }

  .logoContainer {
    padding: 5px;
    justify-content: center;
  }

  .logoContainer img {
    width: 168px;
    height: 75px;
  }

  .footerContent {
    border-bottom: none;
    flex-direction: column;
  }

  .footerElements {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  .optionsContainer {
    flex-direction: column;
    margin-top: 1rem;
  }

  .optionsContainer a {
    margin: 0.5rem;
  }

  .icons {
    margin-top: 1rem;
    border-top: 1px solid var(--saver-inactive);
    width: 90%;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .icons p {
    font-size: 1rem;
  }

  .socialIcons {
    width: 20%;
    height: 100%;
  }
}

@media screen and (max-width: 414px) {
  .banner {
    max-height: 139px;
  }

  .content h1 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    font-size: 20px;
    margin-bottom: 1rem;
  }

  .copyrightHolder p {
    padding-bottom: 1rem;
  }
}

@media screen and (max-width: 375px) and (max-height: 812px) {
  .banner {
    height: 20vh;
  }
}

@media screen and (max-width: 375px) and (max-height: 667px) {
  .banner {
    height: 25vh;
  }
}
