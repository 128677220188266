.searchBar {
  width: 70%;
  outline: none;
}

.searchBar input {
  height: 35px;
  width: 100%;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border: 1px solid #c9c9c9;
  border-left: 4px solid var(--saver-red);
  padding: 5px;
  color: #888888;
  background: url(../assets/search.svg) no-repeat scroll 7px 7px #ffffff;
  background-position: left center;
  background-position-x: 12px;
  text-indent: 36px;
  filter: drop-shadow(0px 1.5px 2.5px rgba(218, 218, 218, 0.6));
}

.searchBar input:focus {
  outline: none;
  filter: drop-shadow(0px 1.5px 2px rgba(243, 167, 167, 0.6));
}

.searchDetails {
  font-family: 'Montserrat', sans-serif;
  font-size: 10px;
  color: var(--saver-inactive);
  margin: 1rem;
}
