.container {
  padding-top: 2rem;
  margin-bottom: 2.5rem;
  display: flex;
  width: 80%;
  max-width: 1440px;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 auto;
  z-index: 1;
}

.sub2Container {
  padding-top: 2rem;
  margin-bottom: 2rem;
  display: flex;
  width: 50%;
  max-width: 1440px;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 auto;
  z-index: 1;
}

.tabsContainer {
  padding-top: 3rem;
}

.tabsHeader {
  background-color: transparent;
  border-bottom: 2px solid var(--saver-separator);
  width: 100%;
}

.tabTitle {
  background-color: transparent;
  border: none;
  padding-bottom: 0.5rem;
  color: var(--saver-textColor);
  font-size: medium;
  margin: 0rem 3rem 0rem;
  font-family: 'Montserrat', sans-serif;
  cursor: pointer;
}

.disabledTab {
  background-color: transparent;
  border: none;
  padding-bottom: 0.5rem;
  color: var(--saver-underline);
  font-size: medium;
  margin: 0rem 3rem 0rem;
  font-family: 'Montserrat', sans-serif;
  cursor: pointer;
}

.active {
  font-weight: 600;
  border-bottom: 3px solid var(--saver-red);
}

.tabTitle:hover {
  font-weight: 600;
}

.tabContent {
  background-color: #fafafa;
  padding: 1rem;
  padding-top: 2.25rem;
}

.grid {
  display: grid;
  font-family: 'Montserrat', sans-serif;
  gap: 1.5rem;
  row-gap: 2rem;
  grid-template-columns: repeat(auto-fill, minmax(364px, 1fr));
  font-size: small;
}

.noData {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-column: span 3;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1.5rem;
  padding-top: 5rem;
  color: var(--saver-textColor);
  font-size: 1.1rem;
  background-color: white;
}

.couponGrid {
  display: grid;
  grid-gap: 0.75rem;
  gap: 0.75rem;
  grid-row-gap: 2rem;
  row-gap: 2rem;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
}

.header {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 0;
  padding: 0.5rem;
  margin-bottom: 2%;
}

.logo {
  align-self: flex-start;
}

.slogan {
  margin-left: 1rem;
  margin-top: -0.5rem;
}

.title {
  font-weight: 700;
  size: 18px;
  color: var(--saver-textColor);
  margin-bottom: 0.1rem;
}

.header span {
  font-weight: normal;
  color: var(--saver-textColor);
  margin-top: -0.25rem;
  font-size: 14px;
}

.tabsWrapper {
  padding: 0;
  display: flex;
  flex-direction: column;
}

.subContent {
  font-family: 'Montserrat', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0;
  color: var(--saver-titles);
  line-height: 1.5rem;
}

.subContentInfo {
  font-family: 'Montserrat';
  color: black;
  font-size: 14px;
  font-style: normal;
  letter-spacing: -0.4099999964237213px;
  font-weight: 500;
}

.subLink {
  color: var(--saver-red);
  font-weight: 600;
  text-decoration: underline;
}

.subLink:hover {
  color: var(--saver-red);
}

.subPrivacy {
  text-decoration: underline;
  cursor: pointer;
  margin-left: 0.25rem;
}

.sectionTitle {
  font-size: 18px;
  text-decoration: underline;
  margin: 0;
  color: black;
}

.btnHolder {
  margin: 1rem 0;
  padding: 0.5rem 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.btnHolder img {
  margin: 0.75rem;
}

.modalContent {
  background-color: white;
  padding: 1rem;
}

.loginModal {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 4% auto;
  width: 23rem;
  height: 10rem;
  box-shadow: 0px 3px 10px rgba(173, 179, 191, 0.3);
  outline: 0;
  border-radius: 8px;
}

.loginModal h1 {
  font-size: 20px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 8px;
  padding-left: 1rem;
}

.modalP {
  font-size: 1rem;
  font-weight: 500;
  margin: 0 auto;
  margin-bottom: 10px;
  margin-top: -4px;
}

.loginModal div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modalLink {
  font-size: 0.8rem;
  color: var(--saver-textColor);
  margin: 3px;
}

.closeModal {
  font-size: 0.8rem;
  margin: 3px;
  color: var(--saver-textColor);
  cursor: pointer;
}

.closeModal:hover {
  color: black;
  font-weight: bold;
}

@media screen and (max-width: 820px) {
  .header {
    flex-direction: column;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .slogan {
    margin: 0;
  }

  .container {
    width: 90%;
  }

  .btnHolder {
    margin: 0.5rem 0;
    padding: 0.25rem;
    flex-direction: column;
  }

  .tabsContainer {
    margin-top: -1rem;
  }

  .tabsHeader {
    font-size: small;
  }

  .tabTitle {
    font-size: small;
  }

  .disabledTab {
    font-size: small;
  }

  .active {
    font-size: small;
  }

  .couponGrid {
    display: grid;
    font-family: 'Montserrat', sans-serif;
    gap: 1rem;
    grid-template-columns: repeat(auto-fill, minmax(20rem, 0.5fr));
  }
}

@media (min-width: 210px) {
  .sub2Container {
    width: 65%;
  }

  .grid {
    /* display: inherit; */
    grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
  }

  .active {
    height: 2rem;
  }

  .tabsHeader {
    white-space: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .tabsHeader::-webkit-scrollbar {
    display: none;
  }
}

@media (min-width: 320px) {
  .sub2Container {
    width: 65%;
  }
}

@media (min-width: 576px) {
  .sub2Container {
    width: 65%;
  }
}

@media (min-width: 630px) {
  .grid {
    display: grid;
    gap: 0.75rem;
    row-gap: 2rem;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  }

  .container {
    width: 90%;
  }

  .btnHolder {
    margin: 0.25rem 0;
    padding: 0.16rem;
  }

  .tabsHeader {
    background-color: transparent;
    border-bottom: 2px solid var(--saver-separator);
    width: 100%;
  }

  .tabTitle {
    padding-bottom: 0.5rem;
    font-size: small;
    margin: 0rem 1.1rem 0rem;
  }
}

@media (min-width: 768px) {
  .grid {
    display: grid;
    gap: 0.75rem;
    row-gap: 2rem;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  }

  .container {
    width: 90%;
  }

  .btnHolder {
    margin: 0.25rem 0;
    padding: 0.16rem;
  }

  .tabsHeader {
    background-color: transparent;
    border-bottom: 2px solid var(--saver-separator);
    width: 100%;
    white-space: initial;
  }

  .tabTitle {
    padding-bottom: 0.5rem;
    font-size: small;
    margin: 0rem 1.1rem 0rem;
  }

  .sub2Container {
    width: 50%;
  }
}

@media (min-width: 992px) {
  .tabTitle {
    font-size: inherit;
  }

  .sub2Container {
    width: 50%;
  }  
}

@media (min-width: 1200px) {
  .sub2Container {
    width: 50%;
  } 
}

@media (min-width: 1400px) {
  .sub2Container {
    width: 50%;
  }
}