.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0.5rem;
  width: 290px;
  max-width: 290px;
  margin: 0.16rem 1rem;
  text-align: center;
  border-radius: 8px;
  border: 0.5px solid var(--saver-navbar);
  box-shadow: 0px 3px 10px rgba(173, 179, 191, 0.7);
  background-color: white;
  cursor: pointer;
  height: 195px;
  max-height: 195px;
}

.card:hover {
  transform: scale(1.01);
}

.title {
  color: var(--saver-red);
  font-weight: 700;
  font-size: 20px;
  flex: 1;
  margin: 0 auto;
  margin-top: 0.4rem;
}

.cardBody {
  padding: 0.75rem;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  vertical-align: middle;
}

.cardItem {
  padding: 0.16rem;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cardImage {
  box-shadow: 0px 3px 10px rgba(173, 179, 191, 0.3);
  border-radius: 8px;
  max-height: 225px;
  max-width: 400px;
}

.merchantName {
  font-size: 0.9rem;
  font-weight: 600;
  color: var(--saver-gray);
}

.merchantLogo {
  padding: 0.25rem;
}

@media screen and (max-width: 1024px) {
  .card {
    width: 180px;
    height: 130px;
    padding: 0.1rem;
  }

  .title {
    font-size: 0.7rem;
  }

  .cardImage {
    border-radius: 4px;
    padding: 0rem;
  }

  .merchantLogo {
    width: 100%;
  }

  .merchantName {
    font-size: 0.7rem;
    padding: 0rem;
  }
}

@media screen and (max-width: 700px) {
  .card {
    width: 243px;
    padding: 0.5rem;
  }

  .cardItem {
    width: 60%;
    padding: 0.5rem;
  }

  .title {
    font-weight: 700;
    font-size: 14px;
  }

  .cardImage img {
    width: 80%;
    margin-top: 0.5rem;
  }
}
