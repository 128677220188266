.container {
  margin: 5% 22%;
}

.header {
  display: flex;
  align-items: center;
}

.header h1 {
  margin-left: 2rem;
  font-weight: 600;
  font-style: normal;
  font-size: 1.6rem;
  letter-spacing: -0.41px;
}

.info {
  font-family: 'Montserrat';
  color: black;
  font-size: 14px;
  font-style: normal;
  letter-spacing: -0.4099999964237213px;
  font-weight: 500;
}

.issue {
  margin-top: 2rem;
}

.saverImage {
  margin-top: 4rem;
  text-align: center;
}

.saverImage img {
  height: 450px;
  box-shadow: 10px 10px 8px #888888;
}

@media (max-width: 768px) {
  .container {
    margin: 3% 8%;
  }
}

@media (max-width: 600px) {
  .container {
    margin: 3% 15%;
  }

  .header img {
    height: 40px;
  }

  .header h1 {
    margin-left: 0.5rem;
    font-size: 1.2rem;
    letter-spacing: -0.41px;
  }

  .saverImage img {
    height: 290px;
    box-shadow: 10px 10px 8px #888888;
  }
}
