.container {
  margin: 2% 26.5%;
}

.container h4 {
  font-size: 1rem;
  font-weight: 300;
  line-height: 17px;
  letter-spacing: -0.4099999964237213px;
}

.header {
  display: flex;
  margin-bottom: 4rem;
}

.header h2 {
  margin-left: 1rem;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: -0.4099999964237213px;
}

.subtitle {
  margin-top: 2rem;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.4099999964237213px;
}

.container p {
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: -0.4099999964237213px;
}

.underline {
  text-decoration: underline;
}

.formContainer {
  background: url('/src/assets/bck_featured.svg') center/cover no-repeat;
  position: relative;
  height: 800px;
}

.form {
  text-align: center;
  margin: 0% 26.5%;
  position: absolute;
}

.input {
  background-position-x: 10px !important;
  background-position-y: 18px !important;
  border: 1px solid #c4c4c4 !important;
  padding: 1rem !important;
  padding-left: 2rem !important;
  width: 60% !important;
}

.comment {
  width: 60%;
  margin: 0.75rem 1rem;
  border: 1px solid #c4c4c4;
  padding: 1rem;
  padding-bottom: 8rem;
  outline: none;
  font-family: 'Montserrat';
}

.button {
  margin-top: 2rem;
  color: white;
  font-weight: bold;
  font-size: 1.3rem;
  letter-spacing: -0.41px;
  background: #e11b22;
  border: none;
  border-radius: 26px;
  padding: 0.8rem 10rem;
}

.disabled {
  margin-top: 2rem;
  color: white;
  font-weight: bold;
  font-size: 1.3rem;
  letter-spacing: -0.41px;
  background: #918f8f;
  cursor: not-allowed;
  border: none;
  border-radius: 26px;
  padding: 0.8rem 10rem;
}

.disabled a {
  pointer-events: none;
}

@media screen and (max-width: 1024px) {
  .container {
    margin: 2% 22%;
  }

  .formContainer {
    margin-top: 3rem;
  }

  .input {
    width: 80% !important;
  }

  .comment {
    width: 80% !important;
  }
}

@media screen and (max-width: 768px) {
  .container {
    margin: 2% 20%;
  }

  .form {
    margin: 0% 0%;
  }

  .input {
    width: 60% !important;
  }

  .comment {
    width: 60% !important;
  }

  .button {
    padding: 0.8rem 7rem;
  }

  .disabled {
    padding: 0.8rem 7rem;
  }
}

@media screen and (max-width: 640px) {
  .container {
    text-align: center;
    margin: 2% 10%;
  }

  .header {
    display: inline;
  }

  .formContainer {
    margin-top: 3rem;
    height: 700px;
  }

  .input {
    width: 80% !important;
  }

  .comment {
    width: 80% !important;
  }

  .button {
    padding: 0.8rem 5rem;
  }

  .disabled {
    padding: 0.8rem 5rem;
  }
}
