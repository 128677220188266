.container {
  padding-top: 3.5rem;
  display: flex;
  width: 80%;
  max-width: 1440px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 0 auto;
  z-index: 1;
}

.largeTitle {
  font-weight: 600;
  font-size: 24px;
  color: var(--saver-titles);
}

.cardTitle {
  font-weight: 600;
  font-size: 16px;
  color: var(--saver-textColor);
}

.profileCard {
  background: #ffffff;
  box-shadow: 0px 3px 10px rgba(173, 179, 191, 0.3);
  border-radius: 6px;
  padding: 0;
  margin-top: 2rem;
  padding-bottom: 0.8rem;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  max-width: 700px;
  width: -webkit-fill-available;
}

.profileInputs {
  width: -moz-fit-content;
  width: fit-content;
  width: -webkit-fill-available;
  margin: 0 1rem;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto;
  /* margin-left: 1rem; */
}

.col {
  margin: 1rem;
}

.switches {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 80%;
}

.switches span {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.description {
  font-family: 'Montserrat', sans-serif;
  font-size: small;
  font-weight: 400;
  text-align: start;
  color: var(--saver-textColor);
}

.addAddress {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 600;
  text-align: start;
  color: var(--saver-red);
  outline: 0;
  border: none;
  cursor: pointer;
  background-color: white;
  margin-top: 1rem;
}

.addAddress:hover {
  transform: scale(1.02);
}

.actions {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
}

.logOut {
  font-family: 'Montserrat', sans-serif;
  font-size: large;
  font-weight: 700;
  text-align: start;
  color: var(--saver-red);
  outline: 0;
  border: none;
  cursor: pointer;
  background-color: white;
  margin-top: 1rem;
}

.deleteAccount {
  color: var(--saver-textColor);
  font-size: small;
  margin-top: 40px;
  font-weight: 400;
}

.deleteAccount-warning {
  font-family: 'Montserrat', sans-serif;
  color: var(--saver-textColor);
  font-weight: 700;
  font-size: x-small;
  margin-bottom: 40px;
}

.logOut:hover {
  transform: scale(1.02);
}

.resetPswd {
  font-family: 'Montserrat', sans-serif;
  font-size: large;
  font-weight: 700;
  text-align: start;
  color: var(--saver-textColor);
  outline: 0;
  border: none;
  cursor: pointer;
  background-color: white;
  margin-top: 1rem;
}

.resetPswd:hover {
  transform: scale(1.02);
}

.modalContent {
  padding: 2rem;
  margin: 2rem auto;
  overflow: auto;
  background-color: white;
  align-self: center;
  max-width: 700px;
  outline: 0;
  border-radius: 8px;
}

.editBtn {
  outline: 0;
  background-color: transparent;
  cursor: pointer;
  border: none;
  margin-bottom: -1.5rem;
  margin-top: 1rem;
}

.editBtn:hover {
  transform: scale(1.025);
}

@media (max-width: 319px) {
  .profileInputs .row {
    flex-flow: wrap;
  }
}

@media (min-width: 320px) {
  .profileInputs .row {
    flex-flow: wrap;
  }
}

@media (min-width: 576px) {
  .profileInputs .row {
    flex-flow: nowrap;
  }
}

@media (min-width: 768px) {}

@media (min-width: 992px) {}

@media (min-width: 1200px) {}

@media (min-width: 1400px) {}

@media screen and (max-width: 640px) {
  .container {
    width: 90%;
  }

  .col {
    margin-left: 0;
    padding-left: 0;
  }

  .row {
    width: 94%;
    /* margin-left: 0; */
  }

  .largeTitle {
    font-size: large;
  }

  .switches span {
    font-size: smaller;
    text-align: left;
  }
}

.formInputError {
  border-bottom: 1px solid #e31e20;
}

.formInput:focus,
.phoneDesc:focus,
.formInputError:focus {
  border: none;
  outline: none;
  border-bottom: 1px solid var(--saver-gray);
}

.formInputError:focus {
  border-bottom: 1px solid #e31e20;
}

.resetModalBody {
  background-color: #ffffff;
}

.formInput {
  width: 100%;
  font-family: 'Montserrat', sans-serif;
  border: none;
  margin: 1rem 0;
  margin-bottom: 6px;
  padding: 0.25rem;
  border-bottom: 1px solid var(--saver-underline);
}

.resetBody {
  max-width: 400px;
  margin: auto;
  padding: 2rem;
}

.resetButtonsContainer {
  max-width: 400px;
  margin: auto;
  text-align: center;
}

.errorMessage {
  font-size: x-small;
  color: #e31e20;
  font-weight: 600;
  margin-bottom: 0.5rem;
  text-align: left;
}

.resetSuccess {
  padding-top: 2rem;
  text-align: center;
}