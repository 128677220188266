.container {
  padding: 2rem 0rem 2rem;
  display: flex;
  width: 80%;
  margin: 0 auto;
  max-width: 1440px;
  flex-direction: column;
  justify-content: flex-start;
}

.body {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
}

.options {
  padding: 0rem;
  margin-right: 1.5rem;
  width: 20%;
  border-radius: 8px;
  font-size: 0.9rem;
  background-color: white;
  border: 0.5px solid var(--saver-navbar);
  box-shadow: 0px 2px 5px rgba(173, 179, 191, 0.2);
}

.content {
  height: max-content;
  padding: 0.5rem;
  padding-top: 0rem;
  padding-left: 1.5rem;
  width: 80%;
  justify-content: center;
}

.titles {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  color: var(--saver-gray);
  justify-content: center;
  align-items: center;
  margin-left: 1.5rem;
  margin-bottom: 1.1rem;
}

.icon {
  margin-right: 0.8rem;
  float: left;
}

.iconRight {
  float: right;
}

.breadcrumb {
  width: 100%;
  color: var(--saver-textColor);
  padding: 1rem;
  padding-top: 0rem;
  padding-left: 0;
  left: 0;
  font-size: small;
}

.breadcrumbList {
  font-family: 'Montserrat', sans-serif;
  display: flex;
  flex: 1;
  flex-direction: row;
  list-style: none;
  color: var(--saver-inactive);
  text-transform: capitalize;
  padding-left: 0;
  cursor: pointer;
}

.breadcrumbList li {
  margin-right: 1.1rem;
  cursor: pointer;
}

.breadcrumbList li::after {
  content: url('../assets/arrow.png');
  position: absolute;
  margin-left: 0.3rem;
}

.breadcrumbList li:last-child::after {
  content: none;
}

.listView {
  padding: 0.5rem;
  width: 100%;
  height: 60vh;
  overflow-y: auto;
  box-shadow: 0px 2px 5px rgba(173, 179, 191, 0.2);
}

.fixedTitle {
  border-bottom: 2px solid var(--saver-navbar);
  width: 100%;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  padding: 0.2rem;
  top: 0;
  left: 0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: white;
}

.listItem {
  font-family: 'Montserrat', sans-serif;
  color: var(--saver-gray);
  padding: 0.6rem;
  border-bottom: 2px solid var(--saver-navbar);
  cursor: pointer;
  font-weight: 400;
}

.listItem:hover {
  transform: scale(1.03);
  color: var(--saver-textColor);
}

@media screen and (max-width: 1024px) {
  .container {
    width: 90%;
    padding-top: 2rem;
  }

  .titles {
    font-size: 0.8rem;
  }

  .options {
    width: 28%;
  }

  .content {
    width: 72%;
  }

  .icon {
    width: 14px;
    margin-right: 0.5rem;
  }

  .listItem {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 820px) {
  .container {
    width: 90%;
  }
}

@media screen and (max-width: 600px) {
  .container {
    width: 92%;
    padding-top: 2rem;
  }

  .options {
    display: none;
  }

  .content {
    width: 100%;
    padding: 0.36rem;
  }
}
