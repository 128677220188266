.container {
  min-height: 90vh;
  padding-top: 3.5rem;
  display: flex;
  width: 80%;
  max-width: 1440px;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 auto;
  z-index: 1;
}

.titles {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  color: var(--saver-gray);
  margin-bottom: 1.5rem;
  margin-top: 1rem;
}

.carousel {
  width: 100%;
  padding: 1rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  max-width: 1440px;
  overflow-x: auto;
}

.carousel::-webkit-scrollbar {
  width: 0 !important;
}

.categoriesCarousel {
  width: 100%;
  padding: 1rem 0;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  max-width: 1440px;
  overflow-x: scroll;
}

.categoriesCarousel::-webkit-scrollbar {
  width: 0 !important;
}

.icon {
  margin-right: 0.8rem;
  float: left;
}

.cards {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
}

.card1,
.card2 {
  width: 49%;
  height: 150px;
  border-radius: 6px;
  color: #ffffff;
  cursor: pointer;
}

.card1:hover,
.card2:hover {
  color: white;
}

.card1 {
  background: var(--saver-card1);
}

.card1 h4 {
  margin: 20px 10px 0px 30px;
  font-size: 1.3rem;
  font-weight: 400;
}

.card1Content {
  display: flex;
  justify-content: space-between;
  margin: 5px 25px;
  align-items: center;
}
.card1Content p {
  margin: 0 5px;
  font-size: 1rem;
  font-weight: 100;
  line-height: 20px;
}

.card1_homes {
  margin: 1px;
}

.card1_homes img {
  width: 100%;
  height: 100%;
}

.card2 {
  background: var(--saver-card2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
}

.card2 img {
  width: 8rem;
  height: 100%;
}

.card2 p {
  margin: 10px 10px;
  font-size: 1.1rem;
  font-weight: 600;
}

.card2 span {
  font-weight: 100;
}

.arrowLeft {
  z-index: 3;
  margin: 4rem 0;
  max-width: fit-content;
  max-height: 44px;
  display: flex;
  background-color: white;
  margin-left: -1.1rem;
}

.arrowRight {
  z-index: 3;
  margin: 4rem 0;
  max-height: 44px;
  max-width: 24px;
  display: flex;
  background-color: transparent;
  margin-right: 0;
}

.carousel button {
  outline: 0;
  border: none;
  background-color: white;
  cursor: pointer;
}

.carouselControl button {
  outline: 0;
  border: none;
  background-color: white;
  cursor: pointer;
}

.carouselControl {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0;
  background-color: transparent;
}

@media screen and (min-width: 2560px) {
  .container {
    min-height: fit-content;
  }

  .arrowRight {
    margin-right: -0.25rem;
  }
}

@media screen and (max-width: 1024px) and (max-height: 1366px) {
  .container {
    min-height: 70vh;
  }
}

@media screen and (width: 1668px) and (height: 2388px) {
  .container {
    min-height: 60vh;
    padding-top: 5rem;
  }

  .cards {
    margin-bottom: 2rem;
  }
}

@media screen and (max-width: 1024px) {
  .card1 h4 {
    margin: 15px 10px 0px 30px;
    font-size: 1.3rem;
    font-weight: 400;
  }

  .card1Content {
    margin: 20px 25px;
  }

  .card2 p {
    font-size: 1rem;
    font-weight: 600;
  }

  .carousel {
    padding: 0;
    margin-bottom: 0.5rem;
  }

  .arrowLeft {
    margin: 2rem 0;
  }

  .arrowRight {
    margin: 2rem 0;
  }
}

@media screen and (max-width: 1000px) {
  .arrowLeft {
    margin: 4rem 0;
  }

  .arrowRight {
    margin: 4rem 0;
  }
}

@media screen and (max-width: 860px) {
  .container {
    width: 90%;
  }
}

@media screen and (max-width: 768px) {
  .card1 h4 {
    font-size: 1.1rem;
  }

  .card2 p {
    font-size: 0.9rem;
  }

  .arrowLeft {
    margin: 2rem 0;
  }

  .arrowRight {
    margin: 2rem 0;
  }
}

@media screen and (max-width: 700px) {
  .card1 h4 {
    font-size: 0.9rem;
  }

  .card1Content p {
    font-size: 0.85rem;
  }

  .card2 p {
    font-size: 0.9rem;
  }

  .categoriesCarousel {
    width: 100%;
    max-width: 720px;
    padding: 0.25rem;
    display: grid;
    grid-template-rows: auto auto;
    grid-auto-flow: column;
    overflow-x: scroll;
  }

  .carousel {
    padding: 0.16rem;
  }

  .titles {
    margin-top: 0;
  }

  .arrowLeft {
    display: none;
  }

  .arrowRight {
    display: none;
  }

  .cards {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .card1,
  .card2 {
    width: 80%;
    margin: 0.5rem;
  }
}

@media screen and (max-width: 428px) {
  .cards {
    flex-direction: column;
  }

  .card1,
  .card2 {
    width: 100%;
  }

  .card1Content {
    margin-top: 20px;
  }

  .card2 {
    margin-top: 1rem;
  }

  .card2 p {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 375px) {
  .card1Content p {
    font-size: 0.9rem;
  }
  .card2 p {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 320px) {
  .card1 h4 {
    font-size: 1rem;
  }

  .card1Content p {
    font-size: 0.8rem;
  }

  .card2 p {
    margin: 5px 8px;
    font-size: 0.8rem;
  }
}
