.card {
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 1.1rem;
  padding-top: 0rem;
  border: 2px solid rgba(149, 149, 149, 0.3);
  box-shadow: 0px 3px 10px rgba(173, 179, 191, 0.3);
  border-radius: 6px;
}

.title {
  color: var(--saver-red);
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  cursor: pointer;
  font-weight: 600;
  font-size: large;
  margin-bottom: 1rem;
}

.body {
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid var(--saver-separator);
  margin-bottom: 0.75rem;
  height: 100%;
}

.description {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: normal;
}

.disclaimer {
  padding-top: 2rem;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 0.5rem;
}

.topBar {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  line-height: 1.5em;
  height: 2.2em;
  margin-bottom: 2rem;
}

.icon {
  margin-left: 0.8rem;
  margin-top: 1.02rem;
  float: right;
  clear: right;
  cursor: pointer;
}

.icon:hover {
  transform: scale(1.1);
}

.favorite {
  width: auto;
  height: 40px;
  margin: 10px 5px 0px 0px;
}

.redeemBtn {
  font-family: 'Montserrat';
  background-color: var(--saver-red);
  width: 50%;
  margin: 0 auto;
  border: none;
  border-radius: 26px;
  padding: 2%;
  color: white;
  font-weight: 500;
}

.redeemBtn:hover {
  transform: scale(1.03);
}
