.imageCard {
  display: flex;
  flex-direction: column;
  background-color: transparent;
  justify-content: center;
  align-items: center;
  margin: 0 0.8rem;
  height: fit-content;
  cursor: pointer;
}

.imageCard p {
  font-size: 0.9rem;
  margin-top: 0.5rem;
  color: var(--saver-textColor);
  font-weight: 500;
}

.imageCard:hover {
  transform: scale(1.05);
}

.categoryIcon {
  width: 7rem;
  height: 7rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.imageContainer {
  background-color: #ECECEC;
  margin-bottom: 0.5rem;
  border: 0.5px solid var(--saver-navbar);
  border-radius: 20px;
  padding: 1rem;
}

@media screen and (max-width: 768px) {
  .imageCard {
    margin: 0rem 0.75rem;
  }

  .categoryIcon {
    width: 5.5rem;
    height: 5rem;
    border-radius: 8px;
  }

  .imageCard p {
    font-size: 0.5rem;
    margin-top: 0.16rem;
  }
}