.grid {
  display: grid;
  font-family: 'Montserrat', sans-serif;
  gap: 2rem;
  grid-template-columns: repeat(auto-fill, minmax(364px, 1fr));
  font-size: small;
}

.card {
  border: 0.5px solid var(--saver-navbar);
  border-radius: 6px;
  box-shadow: 0px 2px 4px rgba(173, 179, 191, 0.8);
  padding: 0.6rem 0.6rem 0;
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  background-color: var(--saver-lightBackground);
  max-height: 225px;
  width: 400px;
}

.favCard {
  border: 0.5px solid var(--saver-navbar);
  border-radius: 6px;
  box-shadow: 0px 2px 4px rgba(173, 179, 191, 0.8);
  padding: 0.6rem;
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* align-items: center; */
  background-color: var(--saver-lightBackground);
  max-height: 300px;
  max-width: 400px;
}

.title {
  color: var(--saver-titles);
  font-weight: 700;
  width: 100%;
  padding-top: 0.1rem;
  margin-bottom: 0.16rem;
  margin-top: 0;
  font-size: 0.9rem;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  cursor: pointer;
}

.favCTitle {
  color: var(--saver-titles);
  font-weight: 700;
  width: 100%;
  padding-top: 0.1rem;
  margin-top: 0;
  font-size: 14px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  cursor: pointer;
}

.favCPromo {
  color: var(--saver-red);
  font-size: 18px;
  font-weight: 700;
  margin-top: -0.25rem;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
}

.favContent {
  color: var(--saver-textColor);
  font-weight: 600;
  font-size: 14px;
  width: 100%;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
}

.favDisclaimer {
  font-size: small;
  font-weight: 400;
  color: var(--saver-titles);
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
}

.title:hover {
  transform: scale(1.01);
}

.favCTitle:hover {
  transform: scale(1.01);
}

.titleHolder {
  display: flex;
  flex-direction: row;
}

.subtitles {
  display: flex;
  flex-direction: column;
  text-align: start;
  margin-top: -6px;
  padding-top: 0;
  margin-left: 0.2rem;
}

.subtitles span {
  margin: 4px 0px;
}

.schedule {
  text-decoration: underline;
  color: var(--saver-textColor);
  font-size: 12px;
  font-weight: 500;
  margin: 3px 0px;
  padding-left: 0px;
  padding-bottom: 2px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.open {
  color: var(--saver-open);
  font-weight: 500;
}

.closed {
  color: var(--saver-red);
  font-weight: 500;
}

.phone {
  color: var(--saver-titles);
  font-weight: 400;
}

.location {
  width: 98%;
  display: flex;
  justify-content: flex-start;
  text-align: start;
  padding: 2%;
}

.noLocation {
  width: 98%;
  display: flex;
  justify-content: flex-start;
  text-align: start;
  padding: 1% 2%;
  border-bottom: none;
}

.location p {
  font-weight: 400;
  color: var(--saver-titles);
  cursor: pointer;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.cardImage {
  background-color: white;
  box-shadow: 0px 3px 10px rgba(173, 179, 191, 0.3);
  border-radius: 8px;
  /* width: 36%; */
  vertical-align: middle;
  max-height: 87px;
  max-width: 113px;
  padding: 0.5rem;
  margin: 0 auto;
  margin-top: 0.5rem;
}

.cardTitle {
  width: 64%;
  padding: 0.16rem;
  padding-top: 0;
  margin-top: 0;
  margin-left: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: start;
}

.topBar {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  line-height: 1.3em;
  height: 2.2em;
  margin-bottom: 1rem;
}

.merchantName {
  width: 80%;
}

.favoriteMark {
  width: 20%;
  float: right;
  cursor: pointer;
}

.actions {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.singleButton {
  display: flex;
  flex-direction: row;
  width: 100%;
  /* justify-content: flex-end; */
  align-items: center;
  padding: 0.5rem;
  padding-left: 0;
  padding-right: 0;
}

.singleButtonRight {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  padding: 0.5rem;
  padding-left: 0;
  padding-right: 0;
}

.singleFavButton {
  display: flex;
  flex-direction: row;
  border-top: 1px solid var(--saver-separator);
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 0.75rem;
  padding-bottom: 0;
  cursor: pointer;
}

.favBody {
  height: 100%;
  overflow-y: scroll;
  padding: 0.25rem;
}

.favBody::-webkit-scrollbar {
  width: 0 !important;
}

.couponsButton {
  border-radius: 26px;
  background-color: var(--saver-red);
  color: var(--saver-background);
  font-weight: 600;
  border: none;
  align-self: flex-start;
  padding: 8px 16px;
  font-weight: 600;
  cursor: pointer;
  min-width: 130px;
  text-align: center;
}

.redeemButton {
  border-radius: 26px;
  background-color: var(--saver-red);
  color: var(--saver-background);
  font-weight: 600;
  border: none;
  float: left;
  padding: 8px 16px;
  font-weight: 600;
  cursor: pointer;
  min-width: 120px;
  text-align: center;
}

.specialsButton {
  border-radius: 26px;
  background-color: var(--saver-red);
  color: var(--saver-background);
  align-self: flex-start;
  font-weight: 600;
  border: none;
  float: right;
  padding: 8px 16px;
  cursor: pointer;
  min-width: 130px;
  text-align: center;
}

.darkButton {
  border-radius: 26px;
  background-color: var(--saver-darkBtn);
  color: var(--saver-background);
  border: none;
  float: right;
  padding: 8px 16px;
  font-weight: 600;
  cursor: pointer;
  min-width: 130px;
  text-align: center;
}

.darkAptButton {
  border-radius: 26px;
  background-color: var(--saver-darkBtn);
  color: var(--saver-background);
  border: none;
  padding: 8px 16px;
  font-weight: 600;
  cursor: pointer;
  min-width: 130px;
  text-align: center;
  float: left !important;
}

.darkButton:hover {
  transform: scale(1.05);
  color: var(--saver-background);
}

.darkButton a:hover {
  color: var(--saver-background);
}

.darkAptButton:hover {
  transform: scale(1.05);
  color: var(--saver-background);
}

.darkAptButton a:hover {
  color: var(--saver-background);
}

.offerButton:hover {
  transform: scale(1.05);
}

.redeemButton:hover {
  transform: scale(1.05);
}

.icon {
  margin-left: 0.8rem;
  float: right;
  clear: right;
  cursor: pointer;
}

.iconLeft {
  float: left;
  margin-right: 0.5rem;
  width: 16px;
}

.icon:hover {
  transform: scale(1.2);
}

.dayListing {
  padding: 0.5rem;
  width: fit-content;
  font-size: small;
  color: var(--saver-titles);
}

.dayDetail {
  display: flex;
  padding: 0.5rem;
  flex-direction: row;
  justify-content: space-between;
}

.dayListed {
  font-weight: 600;
  margin-right: 0.75rem;
}

.isClosed {
  font-style: italic;
  color: var(--saver-red);
}

.noData {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-column: span 3;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2.5rem;
  padding-top: 5rem;
  color: var(--saver-textColor);
  font-size: 1.1rem;
}

.favButton {
  background: none;
  box-sizing: border-box;
  border: none;
  cursor: pointer;
}

@media screen and (max-width: 1024px) {
  .title {
    font-size: 0.8rem;
  }

  .actions {
    width: 100%;
    margin-bottom: 0;
  }

  .cardImage {
    max-height: 77px;
    max-width: 100px;
  }

  .location {
    margin-bottom: -0.5rem;
    margin-top: -0.5rem;
  }

  .darkButton {
    font-size: smaller;
  }

  .darkAptButton {
    font-size: smaller;
    margin-top: 0;
  }

  .offerButton {
    font-size: smaller;
    margin-top: 0.75rem;
  }

  .dayListing {
    font-size: smaller;
  }

  .location {
    font-size: smaller;
  }

  .subtitles {
    font-size: smaller;
  }

  .subtitles span {
    margin: 2px 0px;
  }

  .favCard {
    padding: 0.5rem;
    padding-top: 1rem;
    max-width: 400px;
  }
}

@media screen and (max-width: 820px) {
  .actions {
    margin-top: 3%;
    width: 100%;
  }

  .darkAptButton {
    margin-top: 0.25rem;
  }

  .cardImage {
    max-height: 87px;
    max-width: 113px;
    padding: 0.5rem;
  }

  .favCPromo {
    font-size: 14px;
    margin-top: 0;
  }

  .favContent {
    font-size: 12px;
  }

  .favDisclaimer {
    font-size: 11px;
  }

  .cardImage {
    vertical-align: middle;
    max-height: 73px;
    max-width: 90px;
    padding: 0.75rem;
    margin: 0 auto;
    margin-top: 0.5rem;
  }
}

@media screen and (max-width: 600px) {
  .grid {
    gap: 0.75rem;
    row-gap: 2rem;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  }

  .couponsButton {
    margin-top: 3%;
  }

  .specialsButton {
    margin-top: 3%;
  }

  .darkButton {
    margin-top: 3%;
  }
}
